import React from 'react'
import AgreementDetails from '../components/agreement/AgreementDetails'

export default function AgreementDets() {
  return (
    <>
        <AgreementDetails/>
    </>
  )
}
