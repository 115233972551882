import React from 'react'
import Chat2 from '../components/chatt/Chat'

function HomePage() {
    return (
        <div>
            <Chat2 />
        </div>
    )
}

export default HomePage