import React from 'react'
import DetailsExpert from '../components/details/DetailsExpert'

export default function Details() {
    return (
        <>
            <DetailsExpert />
        </>
    )
}
